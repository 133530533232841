'use client';

import { Heading, Text, VStack } from '@chakra-ui/react';
import { Img, PageContainer, PageContent } from 'components';

import CodeImg from 'assets/code.svg';
import { useLayout } from 'components/Layout/useLayout';

interface Props {
  zmyleError?: ZmyleError;
  error?: Error & { digest?: string };
}

export const ErrorPage = async (props: Props) => {
  const { zmyleError, error } = props;

  const { layoutContent } = useLayout();

  const { error: errorContent } = layoutContent || {};

  const { genericMessage = '', messagesByStatusCode = [] } = errorContent || {};

  const errorStatusCode = String(zmyleError?.status || 500);

  const messageByStatus = messagesByStatusCode.find(
    ({ statusCode }) => statusCode === errorStatusCode,
  );

  const message =
    messageByStatus?.message || genericMessage || error?.message || '';

  return (
    <PageContainer isFullHeight py={0}>
      <PageContent align="center" justify="center" py={0}>
        <VStack maxW="37.5rem" spacing="2.5rem">
          <Heading fontSize="8rem">{errorStatusCode}</Heading>

          <Img src={CodeImg} alt="Code" />

          <Text textAlign="center">{message}</Text>
        </VStack>
      </PageContent>
    </PageContainer>
  );
};
